import * as React from 'react';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';
import 'twin.macro';

import GalleryText from './GalleryText';

interface TextAndImageProps {
  html: string;
  imageTitle: string;
  image: IGatsbyImageData;
}

function TextAndImage({ html, imageTitle, image }: TextAndImageProps) {
  return (
    <div tw="pb-8 max-w-3xl">
      {/* eslint-disable-next-line react/no-danger */}
      <GalleryText dangerouslySetInnerHTML={{ __html: html }} />
      <div tw="pt-6">
        <GatsbyImage alt={imageTitle} image={image} objectFit="contain" />
      </div>
    </div>
  );
}

export default TextAndImage;
