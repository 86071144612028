import * as React from 'react';
import { graphql, PageProps } from 'gatsby';
import { useI18next } from 'gatsby-plugin-react-i18next';

import { Content, Locales } from '../types/QueryTypes';
import Layout from '../components/Layout';
import MinScreenHeightContainer from '../components/MinScreenHeightContainer';
import TextAndImage from '../components/TextAndImage';

// MARK: - PageQuery
export const pageQuery = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: { ns: { eq: "common" }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    about: allContent(filter: { slug: { eq: "/about" } }) {
      nodes {
        id
        slug
        title
        content {
          assets {
            title
            year
            image {
              childImageSharp {
                gatsbyImageData(quality: 90, placeholder: BLURRED)
              }
              id
            }
          }
          text {
            de {
              ...FileFragment
            }
            en {
              ...FileFragment
            }
          }
        }
      }
    }
  }

  fragment FileFragment on File {
    childMarkdownRemark {
      html
    }
  }
`;

// MARK:- Parameter Type Declaration
interface DataProps {
  locales: Locales;
  about?: {
    nodes?: [
      {
        id: string;
        position: number;
        slug?: string;
        title?: string;
        content?: Content;
      }
    ];
  };
}

export default function About({ data }: PageProps<DataProps>) {
  const { language } = useI18next();
  const section = data.about?.nodes?.[0];
  const html = section?.content?.text?.[language]?.childMarkdownRemark?.html;
  const image =
    section?.content?.assets?.[0].image?.childImageSharp?.gatsbyImageData;
  const title = section?.title ?? 'about image';

  return (
    <Layout>
      <MinScreenHeightContainer>
        {html && image && (
          <TextAndImage html={html} imageTitle={title} image={image} />
        )}
      </MinScreenHeightContainer>
    </Layout>
  );
}
